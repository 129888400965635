<template>
  <div class="poster">
    <h1>{{ poster.title }}</h1>
    <br>
    <div>
      <b-collapse v-model="visible" id="collapse-authors" class="mt-2 overflow-hidden">
        <h4>Authors</h4>
        <b-card-group deck>
          <NameBadge 
            v-for="(author, index) in authors"
            v-bind:key="index"
            :person="author"
          />
        </b-card-group>
      </b-collapse>
    </div>
    <div>
      <b-button block variant="light"
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="collapse-authors"
        @click="visible = !visible"
      > {{ this.buttonText }} 
      </b-button>
    </div>
    <br>
    <span v-html="poster.html" ref="content"/>
  </div>
</template>

<script>
import Vue from 'vue'
import NameBadge from './NameBadge.vue'

export default {
  name: 'Project',
  components: {
    NameBadge
  },
  props: {
    poster: Object
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    emitAnchors() {
      const anchors = this.$refs.content.querySelectorAll('a.hidden')
      this.$emit('contentAnchorsChanged', anchors)
    }
  },
  updated() {
    this.emitAnchors()
  },
  mounted() {
    this.emitAnchors()
  },
  computed: {
    authors() {
      return this.poster.authorIDs.map(function(email) {
        return Vue.prototype.$people[email];
      })
    },
    buttonText() {
      if (this.visible)
        return "Hide authors"
      return "Show authors"  
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.poster {
  height: 100%;
  overflow: auto;
  background: #fff;
}
div >>> img { 
  width: 100%; 
}
div >>> .hidden {
  visibility: hidden;
}
</style>

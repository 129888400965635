<template>

  <div>
    <div class="side-nav-group">
    <h5>Posters</h5>
    <b-nav vertical>
      <b-nav-item>
        <router-link to="/"> Overview </router-link>
      </b-nav-item>
      <b-nav-item 
        v-for="(poster, id) in posters"
        v-bind:key="id"
        v-bind:label="poster.short_title"
      >
        <router-link :to="'/posterview/' + id">
          {{ poster.short_title }}
        </router-link>
      </b-nav-item>
    </b-nav>
    </div>

    <div class="side-nav-group">
        <h5>Additional material</h5>
    <b-nav vertical>
      <b-nav-item>Movies</b-nav-item>
      <b-nav-item>Publications</b-nav-item>
      <b-nav-item>People</b-nav-item>
    </b-nav>
    </div>

    <div class="side-nav-group">
        <h5>Information</h5>
        <b-nav vertical>
          <b-nav-item>Time table</b-nav-item>
          <b-nav-item>Links</b-nav-item>
        </b-nav>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'

export default {
  name: 'SideNav',
  data() {
    return {
      isActive: true,
      posters: Vue.prototype.$posters
    }
  }
}
</script>

<style scoped>
.side-nav-group {
  margin-top: 30px;
}
</style>

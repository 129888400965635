<template>

<div>
  <b-card no-body class="overflow-hidden" style="min-width: 355px;  max-width: 425px;">
    <b-row no-gutters>
      <b-col md="2">
        <b-card-img :src="person.pic" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="10">
        <b-card-body>
          <b-card-text>
            <h5>{{ person.name }}</h5>
            {{ person.affiliation }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
  <br>
</div>

</template>

<script>
export default {
  name: 'NameBadge',
  props: {
    person: Object
  }
}
</script>

<style>
</style>

<template>
  <div>
    <b-navbar>
      <!-- class="navbar navbar-light navbar-expand-md px-0 flex-shrink-0" -->
      <router-link to="/">
        <b-navbar-brand>
          <img src="../assets/cosmicsense-logo-typo3scale_02.png"
            height="120px"
            alt="Cosmic Sense logo"/>
        </b-navbar-brand>
      </router-link>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">  
          <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form>
        </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
  export default {
    name: 'Header',
  }
</script>
<template>
    <div id="app" class="container-fluid d-flex flex-column flex-grow-1 vh-100 overflow-hidden">
    <Header/>
    <br>
    <router-view> </router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import Header from './components/Header.vue'
// import MainCarousel from './components/MainCarousel.vue'
// import Project from './components/Project.vue'

export default {
  name: 'App',
  components: {
    Header,
    // MainCarousel,
    // Project,
  },
  data: function() {
    return {
      posters: Vue.prototype.$posters
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>

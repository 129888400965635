<template> 
    <div class="row flex-grow-1 overflow-hidden">
      <div class="col-2 mh-100 overflow-auto py-2">
        <div v-if="showSubNav">
          <div>
            <b-collapse v-model="visible" id="collapse-2" class="mt-2 overflow-hidden">
              <h4>In-poster navigation</h4>
              <b-nav vertical>
                <b-nav-item 
                  v-for="(a,index) in anchors" 
                  v-bind:key="index"
                  v-bind:href="'#' + a.id"
                > {{ a.innerHTML }}
                </b-nav-item>
              </b-nav>
            </b-collapse>
          </div>
          <div>
            <b-button block variant="light"
              :class="visible ? null : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'"
              aria-controls="collapse-2"
              @click="visible = !visible"
            > {{ this.buttonText }} 
            </b-button>
          </div>
        </div>
        <br>
        <!-- <span v-html="poster.navi_html" /> -->
        <h4>Navigation</h4>
        <SideNav/>
      </div>
      <div class="col mh-100 overflow-auto py-2">
        <Project
          v-bind:poster="poster"
          @contentAnchorsChanged="refreshAnchors('refreshAnchors', $event)"
        />
      </div>

      <div class="col-3 mh-100 overflow-auto py-2">
        <div>
          <b-img :src="require('../assets/pix/VC_dummy.png')"
            fluid
            alt="VC dummy"
            center/>
        </div>
        <br>
        <div>
          <b-img :src="require('../assets/pix/chat_dummy.png')"
            fluid
            alt="Chat dummy"
            center/>
        </div>
      </div>
    </div>
</template>

<script>
  import Vue from 'vue'
  import SideNav from './SideNav.vue'
  import Project from './Project.vue'

  export default {
    name: 'PosterView',
    components: {
        SideNav,
        Project,
    },
    data() {
      return {
        visible: true,
        anchors: [],
      }
    },
    computed: {
      showSubNav () {
        return this.anchors.length != 0;
      },
      poster() {
        return Vue.prototype.$posters[this.$route.params.id]
      },
      buttonText() {
        if (this.visible)
          return "Hide poster navigation"
        return "Show poster navigation"  
      },
    },
    methods: {
      refreshAnchors(callbackKey, anchors) {
        this.anchors = anchors;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.teaser
{
  background:#fff;
  margin-top: 20px;
  margin-bottom: 10px;
}
.abstract
{
  background:#fff;
  margin-top: 20px;
}
</style>

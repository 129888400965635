import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue);
Vue.use(VueRouter);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

import HomeView from './components/MainCarousel.vue'
import PosterView from './components/PosterView.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/posterview/:id', component: PosterView } 
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

Vue.use(VueRouter);

Vue.prototype.$posters = {
  "RV01": { 
    html: require("./assets/posters/posterA.md"), 
    title: "Long and verbuous title of Poster A", 
    sub_title: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    abstract: require("./assets/abstracts/abstract_A.md"),
    short_title: "Short title poster A", 
    cover: "https://picsum.photos/1024/480/?image=52",
    authorIDs: ["qwerwt@email.com", "hi@email.com", "Cassie@email.com"] },
  "DD01": { 
    html: require("./assets/posters/posterB.md"), 
    title: "Long and extravagant title of Poster B", 
    sub_title: "Corpore ursos nec nec haec tua nomina fueramque mirantur delapsus clipeum fictaque in ornata coniugis!",
    abstract: require("./assets/abstracts/abstract_B.md"),
    short_title: "Short title poster B", 
    cover: "https://picsum.photos/1024/480/?image=54",
    authorIDs: ["hi@email.com", "jr@email.com", "qwerwt@email.com", "Cassie@email.com"] },
  "RS01": { 
    html: require("./assets/posters/posterC.md"), 
    title: "Long and eloquent title of Poster C", 
    sub_title: "Dignas Picus commoda corque. Cervum ereptamque interea fugit oscula caducas liquidas uterum.",
    abstract: require("./assets/abstracts/abstract_C.md"),
    short_title: "Short title poster C", 
    cover: "https://picsum.photos/1024/480/?image=58",
    authorIDs: ["123@email.com", "blubb@email.com"] },
  "Z01": {
    html: require("./assets/posters/posterD.md"), 
    title: "Long and a bit too complicated title of Poster D", 
    sub_title: "Tamen sibi summis pennis at sibi, tam, aequora nunc fraude turgida, bracchia nudaque fit! Roganda ita annis, ipsa quam quaque, Erycina natalis e omnis.",
    abstract: require("./assets/abstracts/abstract_D.md"),
    short_title: "Short title poster D", 
    cover: "https://picsum.photos/1024/480/?image=55",
    authorIDs: ["jr@email.com", "qwerwt@email.com", "hi@email.com", "Cassie@email.com", "123@email.com", "blubb@email.com"] },
}


Vue.prototype.$people = {
  "jr@email.com" : { 
    name: "Juan Robertson",
    affiliation: "Fraunhofer Institut",
    pic: require("./assets/pix/person4_dummy.png") },
  "Cassie@email.com" : { 
    name: "Cassie Tapia",
    affiliation: "University of Chicago",
    pic: require("./assets/pix/person6_dummy.png") },
  "123@email.com" : { 
    name: "Keyla Mcintyre",
    affiliation: "University of Berkeley",
    pic: require("./assets/pix/person5_dummy.png") },
  "qwerwt@email.com" : { 
    name: "Braylen Walker",
    affiliation: "University of Potsdam",
    pic: require("./assets/pix/person3_dummy.png") },
  "blubb@email.com" : { 
    name: "Meghan Shelton",
    affiliation: "Massachusetts Institute of Technology",
    pic: require("./assets/pix/person2_dummy.png") },
  "hi@email.com" : { 
    name: "Bruno Pittman",
    affiliation: "University of Potsdam",
    pic: require("./assets/pix/person1_dummy.png") },
}



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
    <div class="row flex-grow-1 overflow-auto">
      <div class="col-2 mh-100 overflow-auto py-2">
        <h4>Navigation</h4>
        <SideNav/>
      </div>
      <div class="col mh-100 overflow-auto py-2">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="slidingInt"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <b-carousel-slide
            v-for="(poster, id) in posters"
            v-bind:key="id"
            v-bind:img-src="poster.cover"
          ></b-carousel-slide>
        </b-carousel>

        <!-- v-bind:caption="poster.title"
        v-bind:text="poster.sub_title" -->

        <div class="teaser">
          <h1 v-html="title"/>
          <h4 v-html="subtitle"/>
        </div>
        <!-- <Project :html="posters[this.slide].html"/> -->
      </div>

      <div class="col-3 mh-100 overflow-auto py-2">
        <div class="abstract" v-if="showAbstract">
          <span v-html="htmlAbstract"/>
        </div>
        <b-row>
          <b-col col lg="8">
            <!-- <b-button :pressed.sync="showAbstract" variant="light">{{ buttonText }}</b-button> -->
          </b-col>
          <b-col col lg="4" class="text-right">
            <!-- <b-button variant="light" href="#">Visit poster</b-button> -->
          </b-col>
        </b-row>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import SideNav from './SideNav.vue'
// import Project from './Project.vue'

  export default {
    name: 'MainCarousel',
    components: {
      SideNav
    },
    data() {
      return {
        showAbstract: true,
        posterID: null,
        slide: 0,
        sliding: null,
        posters: Vue.prototype.$posters
      }
    },
    computed: {
      buttonText() {
        if(this.showAbstract) 
            return "Hide Abstract and continue slide show"
        return "Show Abstract"
      },
      slidingInt() {
        if(this.showAbstract) 
          return 5000
        return 5000
      },
      title() {
        console.log(Object.values(this.posters));
        return Object.values(this.posters)[this.slide].title
      },
      subtitle() {
        return Object.values(this.posters)[this.slide].sub_title
      },
      htmlAbstract() {
        return Object.values(this.posters)[this.slide].abstract
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.teaser
{
  background: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}
.abstract
{
  background: #fff;
  margin-top: 20px;
}
</style>
